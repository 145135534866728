@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&?family=IBM+Plex+Mono:wght@300;400;700&display=swap');

:root {
  --primary: rgb(110, 6, 155);
  --primary-dark: rgb(49, 0, 70);
  --primary-light: rgb(154, 54, 196);
  --primary-very-light: rgb(245, 225, 255);
  --secondary: rgb(9, 128, 179);
  --secondary-light: rgb(64, 173, 220);
  --grey-dark: hsla(33, 0%, 10%, 1);
  --grey-medium: rgb(96, 96, 96);
  --grey-light: rgb(201, 201, 201);
  --grey-very-light: rgb(237, 237, 237);
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: scroll;
}

body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0.5rem;
}

/* 
Typography
*/

h2 {
  color: var(--primary);
}

/* 
Layout
*/
.container {
  max-width: 1000px;
  margin: 0 auto;
}
