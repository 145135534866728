.about {
  max-width: 80rem;
  margin: 0 auto;
}

.about__title {
  margin-bottom: 1rem;
}

.about p {
  margin-bottom: 2rem;
}

.about ul {
  list-style: none;
  margin-bottom: 2rem;
}

.about__fav {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.about__fav-cover {
  display: inline-block;
  max-width: 5rem;
  margin-right: 1.5rem;
}

.about__fav-title {
  color: var(--primary);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.about__fav-title:hover {
  text-decoration: underline;
}

.about__fav-authors {
}
