@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&?family=IBM+Plex+Mono:wght@300;400;700&display=swap);
:root {
  --primary: rgb(110, 6, 155);
  --primary-dark: rgb(49, 0, 70);
  --primary-light: rgb(154, 54, 196);
  --primary-very-light: rgb(245, 225, 255);
  --secondary: rgb(9, 128, 179);
  --secondary-light: rgb(64, 173, 220);
  --grey-dark: hsla(33, 0%, 10%, 1);
  --grey-medium: rgb(96, 96, 96);
  --grey-light: rgb(201, 201, 201);
  --grey-very-light: rgb(237, 237, 237);
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: scroll;
}

body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0.5rem;
}

/* 
Typography
*/

h2 {
  color: rgb(110, 6, 155);
  color: var(--primary);
}

/* 
Layout
*/
.container {
  max-width: 1000px;
  margin: 0 auto;
}

.header {
  text-align: center;
  padding: 2rem 0;
}
.header__title-container a {
  text-decoration: none;
}

.header__svg {
  max-width: 70rem;
  display: block;
  margin: 0 auto;
}

.header__nav {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
}

.header__nav-link {
  font-weight: 700;
  color: var(--primary);
  margin: 0 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.header__nav-link--active {
  border-bottom: 2px solid var(--primary);
}

.header__nav-link:hover {
  color: var(--primary-dark);
  border-bottom: 2px solid var(--primary-dark);
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .header__nav-link {
    font-size: 2.2rem;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .header {
    padding: 1rem;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .header__nav {
    flex-wrap: wrap;
  }
  .header__nav-link {
    font-size: 1.8rem;
  }
}

.filters {
  text-align: center;
  max-width: 120rem;
  margin: 0 auto 3rem auto;
}

.filters__years,
.filters__tags,
.filters__show-hide {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.filters__label,
.filters__show-hide {
  font-size: 1.6rem;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: var(--grey-very-light);
  border-radius: 30px;
  padding: 1px 15px;
  margin: 4px 4px;
  border: 2px solid transparent;
  transition: all 0.2s;
}

.filters__label:hover,
.filters__show-hide:hover {
  border: 2px solid var(--primary);
}

.filters__label--active {
  color: white;
  background-color: var(--primary);
}

.filters__label--active:hover {
  background-color: var(--primary-light);
  border: 2px solid transparent;
}

.filters__show-hide {
  background-color: var(--primary);
  color: white;
}
.filters__show-hide:hover {
  border: 2px solid var(--primary);
  color: black;
  background-color: var(--grey-very-light);
}

.filters__meta {
  margin-top: 2rem;
}

.filters__label--meta {
  font-family: inherit;
  border: 2px solid var(--primary);
  border-radius: 10px;
  text-transform: uppercase;
  color: var(--primary);
  background-color: transparent;
  line-height: 1.7;
  transition: all 0.2s;
  padding: 0.6rem 1.8rem;
  margin: 0 0.75rem;
}

.filters__label--meta:hover {
  border: 2px solid transparent;
  color: white;
  background-color: var(--primary);
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.5rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.4rem;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.3rem;
    flex-grow: 1;
    border-radius: 2px;
    margin: 2px;
  }
  .filters__label--meta {
    margin-bottom: 10px;
  }
}

.about {
  max-width: 80rem;
  margin: 0 auto;
}

.about__title {
  margin-bottom: 1rem;
}

.about p {
  margin-bottom: 2rem;
}

.about ul {
  list-style: none;
  margin-bottom: 2rem;
}

.about__fav {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.about__fav-cover {
  display: inline-block;
  max-width: 5rem;
  margin-right: 1.5rem;
}

.about__fav-title {
  color: var(--primary);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.about__fav-title:hover {
  text-decoration: underline;
}

.about__fav-authors {
}

.single {
  padding: 0 1rem;
}

.single__cover {
  float: right;
  max-height: 35rem;
  margin: 1rem 0 1rem 2rem;
  border-radius: 0.5rem;
}

.single__header {
  font-weight: bold;
  line-height: 1.4;
}

.single__titles {
  margin-bottom: 2rem;
}

.single__title {
  font-size: 4rem;
  color: black;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.single__subtitle {
  font-size: 2rem;
  color: var(--grey-dark);
  font-weight: normal;
  font-style: italic;
  /* margin: 5px 0px; */
}

.single__creators {
  margin-bottom: 2rem;
  line-height: 1.5;
  /* padding-left: 1rem;
  border-left: 2px solid var(--primary); */
}

.single__authors {
  font-size: 2.3rem;
  color: var(--primary);
  /* margin: 5px 0px; */
}
.single__ill-and-trans {
  font-size: 1.8rem;
  color: black;
  font-weight: normal;
  /* margin: 5px 0px; */
}

.single__body {
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.single__footer {
  font-size: 1.6rem;
}

.single__star {
}

.single__star--lit {
  color: var(--primary);
}

.single__star--dim {
  color: var(--grey-light);
}

.single__tags-and-links {
  margin-top: 1rem;
}

.single__tag-list {
  margin: 0.5rem 0;
}

.single__label--tag,
.single__external-tag {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: var(--grey-very-light);
  border-radius: 30px;
  padding: 1px 15px;
  margin: 4px 2px;
  border: 2px solid transparent;
  transition: all 0.2s;
}

.single__label--tag:hover {
  border: 2px solid var(--primary);
}

.single__label--active {
  color: white;
  background-color: var(--primary);
}

.single__label--active:hover {
  background-color: var(--primary-light);
  border: 2px solid transparent;
}

.single__external-tag,
.single__external-tag:visited {
  font-weight: 600;
  color: white;
  background-color: var(--secondary-light);
}

.single__external-tag:hover,
.single__external-tag:visited:hover {
  font-weight: 600;
  color: white;
  background-color: var(--secondary);
}

.single__label--tag:not(:last-child),
.single__external-tag:not(:last-child) {
  margin-right: 0.5rem;
}

.single__bottom-nav-btn,
.single__bottom-nav-btn:visited {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primary);
  padding: 0.5rem 1.25rem;
  border: 1px solid var(--primary-light);
  border-radius: 0.75rem;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 3rem;
}

.single__bottom-nav-btn:hover,
.single__bottom-nav-btn:visited:hover {
  background-color: var(--primary-light);
  color: white;
}

.single__bottom-nav-btn--previous {
  float: left;
}
.single__bottom-nav-btn--next {
  float: right;
}

.single__bottom-nav-btn--disabled,
.single__bottom-nav-btn--disabled:visited {
  color: var(--grey-light);
  border-color: var(--grey-light);
}

.single__bottom-nav-btn--disabled:hover,
.single__bottom-nav-btn--disabled:visited:hover {
  color: var(--grey-light);
  border-color: var(--grey-light);
  background-color: white;
  cursor: not-allowed;
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .single__cover {
    max-height: 25rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .single__cover {
    max-height: 15rem;
  }

  .single__title {
    font-size: 2.8rem;
  }

  .single__subtitle {
    font-size: 1.8rem;
  }

  .single__authors {
    font-size: 1.8rem;
  }

  .single__ill-and-trans {
    font-size: 1.6rem;
  }

  .single__desc {
    font-size: 1.5rem;
  }

  .single__footer,
  .single__label--tag,
  .single__external-tag {
    font-size: 1.4rem;
  }

  .single__label--tag,
  .single__external-tag {
    padding: 1px 10px;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .single__cover {
    display: block;
    float: none;
    width: 90%;
    height: auto;
    max-height: none;
    margin: 2rem auto;
  }
}

#covers__container {
  margin: 35px 30px;
}

@media only screen and (min-width: 100em) {
  #covers__container {
    margin: 35px auto;
    max-width: 1540px;
  }
}

@media only screen and (max-width: 46.875em) {
  #covers__container {
    margin: 35px 10px;
  }
}

@media only screen and (max-width: 37.5em) {
  #covers__container {
    margin: 35px 5px;
  }
}

.list__container {
  margin: 0 auto;
  max-width: 90rem;
  padding: 2rem;
}

.list__book {
  display: flex;
  margin-bottom: 2rem;
  width: 45%;
}

.list__book--left {
  float: left;
  clear: left;
}

.list__book--right {
  float: right;
}

.list__cover {
  max-width: 10rem;
  margin-right: 2rem;
}

.list__details {
  display: block;
}

.list__title,
.list__title:visited {
  text-decoration: none;
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
}

.list__authors {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.list__stars {
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .list__book {
    margin-bottom: 2rem;
    width: 100%;
  }

  .list__book--left,
  .list__book--right {
    float: none;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .list__container {
    padding: 1rem;
  }

  .list__book {
    margin-bottom: 1.5rem;
    padding: 0;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .list__title {
    font-size: 2rem;
  }
  .list__authors {
    font-size: 1.8rem;
  }
}

