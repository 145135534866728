.single {
  padding: 0 1rem;
}

.single__cover {
  float: right;
  max-height: 35rem;
  margin: 1rem 0 1rem 2rem;
  border-radius: 0.5rem;
}

.single__header {
  font-weight: bold;
  line-height: 1.4;
}

.single__titles {
  margin-bottom: 2rem;
}

.single__title {
  font-size: 4rem;
  color: black;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.single__subtitle {
  font-size: 2rem;
  color: var(--grey-dark);
  font-weight: normal;
  font-style: italic;
  /* margin: 5px 0px; */
}

.single__creators {
  margin-bottom: 2rem;
  line-height: 1.5;
  /* padding-left: 1rem;
  border-left: 2px solid var(--primary); */
}

.single__authors {
  font-size: 2.3rem;
  color: var(--primary);
  /* margin: 5px 0px; */
}
.single__ill-and-trans {
  font-size: 1.8rem;
  color: black;
  font-weight: normal;
  /* margin: 5px 0px; */
}

.single__body {
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.single__footer {
  font-size: 1.6rem;
}

.single__star {
}

.single__star--lit {
  color: var(--primary);
}

.single__star--dim {
  color: var(--grey-light);
}

.single__tags-and-links {
  margin-top: 1rem;
}

.single__tag-list {
  margin: 0.5rem 0;
}

.single__label--tag,
.single__external-tag {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: var(--grey-very-light);
  border-radius: 30px;
  padding: 1px 15px;
  margin: 4px 2px;
  border: 2px solid transparent;
  transition: all 0.2s;
}

.single__label--tag:hover {
  border: 2px solid var(--primary);
}

.single__label--active {
  color: white;
  background-color: var(--primary);
}

.single__label--active:hover {
  background-color: var(--primary-light);
  border: 2px solid transparent;
}

.single__external-tag,
.single__external-tag:visited {
  font-weight: 600;
  color: white;
  background-color: var(--secondary-light);
}

.single__external-tag:hover,
.single__external-tag:visited:hover {
  font-weight: 600;
  color: white;
  background-color: var(--secondary);
}

.single__label--tag:not(:last-child),
.single__external-tag:not(:last-child) {
  margin-right: 0.5rem;
}

.single__bottom-nav-btn,
.single__bottom-nav-btn:visited {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primary);
  padding: 0.5rem 1.25rem;
  border: 1px solid var(--primary-light);
  border-radius: 0.75rem;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 3rem;
}

.single__bottom-nav-btn:hover,
.single__bottom-nav-btn:visited:hover {
  background-color: var(--primary-light);
  color: white;
}

.single__bottom-nav-btn--previous {
  float: left;
}
.single__bottom-nav-btn--next {
  float: right;
}

.single__bottom-nav-btn--disabled,
.single__bottom-nav-btn--disabled:visited {
  color: var(--grey-light);
  border-color: var(--grey-light);
}

.single__bottom-nav-btn--disabled:hover,
.single__bottom-nav-btn--disabled:visited:hover {
  color: var(--grey-light);
  border-color: var(--grey-light);
  background-color: white;
  cursor: not-allowed;
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .single__cover {
    max-height: 25rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .single__cover {
    max-height: 15rem;
  }

  .single__title {
    font-size: 2.8rem;
  }

  .single__subtitle {
    font-size: 1.8rem;
  }

  .single__authors {
    font-size: 1.8rem;
  }

  .single__ill-and-trans {
    font-size: 1.6rem;
  }

  .single__desc {
    font-size: 1.5rem;
  }

  .single__footer,
  .single__label--tag,
  .single__external-tag {
    font-size: 1.4rem;
  }

  .single__label--tag,
  .single__external-tag {
    padding: 1px 10px;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .single__cover {
    display: block;
    float: none;
    width: 90%;
    height: auto;
    max-height: none;
    margin: 2rem auto;
  }
}
