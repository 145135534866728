.filters {
  text-align: center;
  max-width: 120rem;
  margin: 0 auto 3rem auto;
}

.filters__years,
.filters__tags,
.filters__show-hide {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.filters__label,
.filters__show-hide {
  font-size: 1.6rem;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: var(--grey-very-light);
  border-radius: 30px;
  padding: 1px 15px;
  margin: 4px 4px;
  border: 2px solid transparent;
  transition: all 0.2s;
}

.filters__label:hover,
.filters__show-hide:hover {
  border: 2px solid var(--primary);
}

.filters__label--active {
  color: white;
  background-color: var(--primary);
}

.filters__label--active:hover {
  background-color: var(--primary-light);
  border: 2px solid transparent;
}

.filters__show-hide {
  background-color: var(--primary);
  color: white;
}
.filters__show-hide:hover {
  border: 2px solid var(--primary);
  color: black;
  background-color: var(--grey-very-light);
}

.filters__meta {
  margin-top: 2rem;
}

.filters__label--meta {
  font-family: inherit;
  border: 2px solid var(--primary);
  border-radius: 10px;
  text-transform: uppercase;
  color: var(--primary);
  background-color: transparent;
  line-height: 1.7;
  transition: all 0.2s;
  padding: 0.6rem 1.8rem;
  margin: 0 0.75rem;
}

.filters__label--meta:hover {
  border: 2px solid transparent;
  color: white;
  background-color: var(--primary);
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.5rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.4rem;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .filters__label,
  .filters__show-hide {
    font-size: 1.3rem;
    flex-grow: 1;
    border-radius: 2px;
    margin: 2px;
  }
  .filters__label--meta {
    margin-bottom: 10px;
  }
}
