.header {
  text-align: center;
  padding: 2rem 0;
}
.header__title-container a {
  text-decoration: none;
}

.header__svg {
  max-width: 70rem;
  display: block;
  margin: 0 auto;
}

.header__nav {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
}

.header__nav-link {
  font-weight: 700;
  color: var(--primary);
  margin: 0 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.header__nav-link--active {
  border-bottom: 2px solid var(--primary);
}

.header__nav-link:hover {
  color: var(--primary-dark);
  border-bottom: 2px solid var(--primary-dark);
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .header__nav-link {
    font-size: 2.2rem;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .header {
    padding: 1rem;
  }
}

/* 350px */
@media only screen and (max-width: 21.875em) {
  .header__nav {
    flex-wrap: wrap;
  }
  .header__nav-link {
    font-size: 1.8rem;
  }
}
